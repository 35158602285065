<template>
    <div v-if="!isAuthenticated">
      <NobodyMenuContent/>
    </div>
    <div v-else>
      <UserMenuContent/>
    </div>
</template>

<script>
import NobodyMenuContent from '@/components/NobodyMenuContent.vue'
import UserMenuContent from '@/components/UserMenuContent.vue'

  export default {
    name: 'NavigationMenuLeft',
    components: {
      NobodyMenuContent,
      UserMenuContent,
    },
    computed: {
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      }
    }
  };
</script>
