import Vue from 'vue'
import App from './App'
import router from './router'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import { store } from './store'
import firebase from 'firebase'
import vuetify from './plugins/vuetify';

Vue.use(Vuetify)

firebase.initializeApp({
  apiKey: 'AIzaSyBNHBfZE6R6YVOboHYwDUF2YGANY9jLe0w',
  authDomain: 'youdj-cloud.firebaseapp.com',
  databaseURL: 'https://youdj-cloud-default-rtdb.firebaseio.com',
  projectId: 'youdj-cloud',
  storageBucket: 'youdj-cloud.appspot.com',
  messagingSenderId: '295962011379',
  appId: '1:295962011379:web:a2c3acb41c9497154f3986',
  measurementId: 'G-JKMZ4V87FV',
})

Vue.config.productionTip = false

/* eslint-disable no-new */
const unsubscribe = firebase.auth()
.onAuthStateChanged((firebaseUser) => {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    vuetify,

    created () {
      if (firebaseUser) {
        store.dispatch('autoSignIn', firebaseUser)
      }
    }
  })
  unsubscribe()
})
