<template>
  <div name="signup">
    <v-btn class="signup" color="indigo" to="/signup" x-small>Sign up</v-btn>
    <v-btn class="signin" color="indigo" to="/signin" x-small>Sign in</v-btn>
  </div>
</template>

<script>
export default{
  name: 'LoginMenu',
};
</script>

<style lang="scss">
.signup {
    position: absolute;
    right: 150px;
}
.signin {
  position: absolute;
  right: 75px;
}
</style>
