<template>
    <v-app>
      <v-app-bar app clipped-right color="black " dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title>
          <v-btn to="/" plain text> Last Viking Dj =)</v-btn>
      </v-toolbar-title>
        <v-spacer/>
        <div><v-btn flat @click="userSignOut" v-if="isAuthenticated">
  Sign Out
</v-btn></div>
        <v-app-bar-nav-icon right v-if="isAuthenticated" @click.stop="drawerRight = !drawerRight" />
        <div v-if="!isAuthenticated"><LoginMenu/></div>

            </v-app-bar>
            <div v-if="isAuthenticated">
               <v-navigation-drawer v-model="drawerRight" app temporary clipped dark right>
                    <UserRightMenu/>
                </v-navigation-drawer>
            </div>
            <v-navigation-drawer v-model="drawer" app temporary clipped dark>
                  <NavigationMenuLeft/>
              </v-navigation-drawer>
                  <v-content>
                            <router-view></router-view>
                      </v-content>
                    <v-footer app color="black" class="white--text">
                  <span>Last Viking Party</span>
                <v-spacer/>
                <span>We are alive for a music and will never die</span>
                <v-spacer />
              <span> 2021 (c)</span>
            </v-footer>
            </v-app>
</template>

<script>
  import NavigationMenuLeft from './components/NavigationMenuLeft.vue'
  import LoginMenu from './components/LoginMenu.vue'
  import UserRightMenu from './components/UserRightMenu.vue'


  export default {
    data (){
      return{
        drawer: null,
        drawerRight: null,
      }
    },
      computed: {

        isAuthenticated () {
          return this.$store.getters.isAuthenticated
        }
      },

      methods: {
        userSignOut () {
          this.$store.dispatch('userSignOut')
        }
      },

      components: {
        UserRightMenu,
        NavigationMenuLeft,
        LoginMenu,
      }
  }
</script>
<style lang="scss">

</style>
