<template>
  <div class="UserMenuContent">
  <div class="DailyEventName"> Hello User =3 </div>
  <v-divider dark :inset="inset"></v-divider>
  <v-list>
  <v-list-item
    v-for="(item, index) in menu" :key="index"
    @click="$router.push({ path: item.link })">
    <v-list-item-action>
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-content>
  <v-list-item-title>
    {{ item.name }}
  </v-list-item-title>
   </v-list-item-content>
  </v-list-item>
  </v-list>
</div>
</template>

<script>

  export default {
    name: 'UserRightMenu',
    data () {
      return {
        menu: [
          { icon: 'mdi-star-outline', name: 'Start ton mix!', link:'/StartScreenShare', },
          { icon: 'mdi-wallet-giftcard', name: 'Djs mix Reservation', link:'/djsmixreservation', },
          { icon: 'mdi-creation', name: 'Request special Dj', link:'/djsmixreservation', },
          { icon: 'mdi-spa-outline', name: 'Guide for Djs', link:'/forum', },
          { icon: 'mdi-phone-message', name: 'Chat with Support', link:'/suplivechat', },
        ],
      };
    },
  };
</script>
<style lang="scss">

</style>
