<template>
  <div class="UserMenuContent">
          <div class="underline">Event of the Day</div>
      <div class="DailyEventName">Free VIP for All!</div>
      <div class="DailyEventName">  </div>
      <v-divider dark :inset="inset"></v-divider>
    <v-list>
      <v-list-item
        v-for="(item, index) in menu" :key="index"
        @click="$router.push({ path: item.link })">
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
      <v-list-item-title>
        {{ item.name }}
      </v-list-item-title>
       </v-list-item-content>
    </v-list-item>
  </v-list>
      <v-divider dark :inset="inset"></v-divider>
    </div>
</template>

<script>

export default {
  name: 'UserMenuContent',
  data () {
    return {
      menu: [
        { icon: 'mdi-cast-audio', name: 'Djs Broadcasts', link:'/currentpartyevents', },
        { icon: 'mdi-podcast', name: 'Podcasts', link:'/podcasts', },
        { icon: 'mdi-boombox', name: 'Music styles', link:'/podcasts', },
        { icon: 'mdi-calendar-text', name: 'Events Calendar', link:'/djsmixreservation', },
        { icon: 'mdi-gamepad-square-outline', name: 'Games', link:'/djsmixreservation', },
        { icon: 'mdi-google-circles-communities', name: 'Forum', link:'/forum', },
        { icon: 'mdi-book-open', name: 'Newsfeed', link:'/djsmixreservation', },
        { icon: 'mdi-wrench-outline', name: 'F.A.Q / docs', link:'/guestsupport', },
        { icon: 'mdi-bank-outline', name: 'Accepted Payment', link:'/newsfeed', },
        { icon: 'mdi-newspaper-variant-outline', name: 'About TeamTop10', link:'/about', },
        { icon: 'mdi-tablet-cellphone', name: 'Mobile version', link:'/about', },
        { icon: 'mdi-copyright', name: 'Licence', link:'/licence', },
      ],
    };
  },
};
</script>
<style lang="scss">
.underline {
  padding-top: 7px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: white;
  background: linear-gradient(to left, #00c503, #ff7400, #9200c5 100%);
  background-position: 0 100%;
  background-size: 100% 2.5px;
  background-repeat: repeat-x;
}
.DailyEventName {
font-size: 16px;
text-align: center;
color: gold;
}
</style>
