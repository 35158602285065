import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'

const routerOptions = [
  { path: '/', component: 'Landing' },
  { path: '/contact', name: 'contact', component: 'Contact' },
  { path: '/about', name: 'about', component: 'About' },
  { path: '/payment', name: 'payment', component: 'Payment' },
  { path: '/DjMixStartAndroid', name: 'DjMixStartAndroid', component: 'DjMixStartAndroid' },
  { path: '/DjMixStartPc', name: 'DjMixStartPc', component: 'DjMixStartPc' },
  { path: '/UserHome', name: 'UserHome', component: 'UserHome' },
  { path: '/signin', component: 'Signin' },
  { path: '/signup', component: 'Signup' },
  { path: '/StartScreenShare',name: 'StartScreenShare', component: 'StartScreenShare' },
  { path: '/home', component: 'Home', meta: { requiresAuth: true } },
  { path: '*', component: 'NotFound' }
]

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`@/components/${route.component}.vue`)
  }
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = firebase.auth().currentUser
  if (requiresAuth && !isAuthenticated) {
    next('/signin')
  } else {
    next()
  }
})

export default router
